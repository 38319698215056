<template>
    <div class="row m-0">
        <div class="col-12">
            <div class="row headers text-dark text-center py-3">
            <h4 class="m-0 mx-auto"> {{ $t("achievement") }} </h4>
            </div>
            <div class="row">
                <ul class="nav nav-tabs nav-pills lined flex-sm-row text-center w-100 mt-2 mb-3 p-0" id="pills-tab"
                    role="tablist">
                    <li class="nav-item flex-sm-fill" role="presentation">
                        <a class="nav-link text-uppercase font-weight-bold rounded-0 active" id="pills-achievement-tab"
                           data-toggle="pill" data-target="#pills-achievement" type="button" role="tab"
                           aria-controls="pills-achievement" aria-selected="true">
                            {{ $t("achievement") }}
                        </a>
                    </li>
                    <li class="nav-item flex-sm-fill" role="presentation">
                        <a class="nav-link text-uppercase font-weight-bold rounded-0" id="pills-courses-tab"
                           data-toggle="pill" data-target="#pills-courses" type="button" role="tab"
                           aria-controls="pills-courses" aria-selected="false">
                            {{ $t("courses") }}
                        </a>
                    </li>
                    <li class="nav-item flex-sm-fill" role="presentation">
                        <a class="nav-link text-uppercase font-weight-bold rounded-0" id="pills-blog-tab"
                           data-toggle="pill" data-target="#pills-blog" type="button" role="tab"
                           aria-controls="pills-blog" aria-selected="false">
                            {{ $t("blog") }}
                        </a>
                    </li>
                </ul>
                <div class="tab-content sections py-0 px-3" id="pills-tabContent">
                    <div class="tab-pane fade show active pb-3" id="pills-achievement" role="tabpanel"
                         aria-labelledby="pills-achievement-tab">
                        <div class="card w-100 border-0">
                            <div class="card-title mb-0">
                                <div class="float-left">
                                    <div class="media mt-2">
                                        <img src="@/assets/images/ieee.png" class="place-logos rounded mt-3" alt="fum">
                                        <div class="media-body ml-2">
                                            <span class="d-block" v-html="$t('explainable_holodetect')"></span>
                                            <span class="d-block extra-small"
                                                  v-html="$t('explainable_holodetect_sub')"></span>
                                            <span class="d-block"> <small> <a
                                                    href="https://ieeexplore.ieee.org/document/10326276"
                                                    target="_blank"> {{
                                                $t("explainable_holodetect_site")
                                                }} </a> </small> </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="float-right mt-2">
                                    <small> {{ $t("explainable_holodetect_time") }} </small>
                                    <vs-button class="m-0 mt-1 ml-auto" href="/images/explainable_holodetect.jpg"
                                               size="mini" transparent
                                               active blank color="dark">
                                        <i class='bx bx-certification mr-1'></i> {{ $t("certificate") }}
                                    </vs-button>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="w-100">
                                <div class="float-left">

                                </div>
                                <div class="float-right">

                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                        <div class="card w-100 border-0 mt-4">
                            <div class="card-title mb-0">
                                <div class="float-left">
                                    <div class="media mt-2">
                                        <img src="@/assets/images/fum.png" class="uni-logos" alt="fum">
                                        <div class="media-body ml-2">
                                            <span class="d-block"> {{ $t("iccke") }} </span>
                                            <span class="d-block small"> {{ $t("iccke_sub") }} </span>
                                            <span class="d-block"> <small> <a
                                                    href="https://iccke.um.ac.ir/2022/Home/Content?id=3032"
                                                    target="_blank"> {{
                                                $t("iccke_site")
                                                }} </a> </small> </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="float-right mt-2">
                                    <small> {{ $t("iccke_time") }} </small>
                                    <vs-button class="m-0 mt-1 ml-auto" href="/images/iccke.jpg" size="mini" transparent
                                               active blank color="dark">
                                        <i class='bx bx-certification mr-1'></i> {{ $t("certificate") }}
                                    </vs-button>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="w-100">
                                <div class="float-left">

                                </div>
                                <div class="float-right">

                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                        <div class="card w-100 border-0 mt-4">
                            <div class="card-title mb-0">
                                <div class="float-left">
                                    <div class="media mt-2">
                                        <img src="@/assets/images/aichallenge.jpg" class="place-logos rounded"
                                             alt="sharif">
                                        <div class="media-body ml-2">
                                            <span class="d-block"> {{ $t("aichallenge_rank") }} </span>
                                            <span
                                                class="d-block"> AI Challenge - Sharif University Of Technology </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="float-right mt-2">
                                    <small> {{ $t("aichallenge_time") }} </small>
                                    <vs-button class="m-0 mt-1 ml-auto" href="/images/aichallengecertificate.jpg"
                                               size="mini" transparent active blank color="dark">
                                        <i class='bx bx-certification mr-1'></i> {{ $t("certificate") }}
                                    </vs-button>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="w-100">
                                <div class="float-left">

                                </div>
                                <div class="float-right">

                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                        <div class="text-center">
                            <vs-button class="d-inline mt-3" transparent active color="dark" data-toggle="collapse"
                                       data-target="#collapseAchievement" aria-expanded="false"
                                       aria-controls="collapseAchievement">
                                {{ $t("more") }}
                            </vs-button>
                        </div>
                        <div class="collapse" id="collapseAchievement">
                            <div class="card w-100 border-0 mt-4">
                                <div class="card-title mb-0">
                                    <div class="float-left">
                                        <div class="media mt-2">
                                            <img src="@/assets/images/acm.png"
                                                 class="place-logos img-thumbnail border-0"
                                                 alt="acm">
                                            <div class="media-body ml-2">
                                                <span class="d-block"> {{ $t("acm_judge") }} </span>
                                                <span class="d-block"> {{ $t("iaum") }} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="float-right mt-2">
                                        <small> {{ $t("judge_time") }} </small>
                                        <vs-button class="m-0 mt-1 ml-auto" href="/images/judgecertificate.jpg"
                                                   size="mini"
                                                   transparent active blank color="dark">
                                            <i class='bx bx-certification mr-1'></i> {{ $t("certificate") }}
                                        </vs-button>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="w-100">
                                    <div class="float-left">

                                    </div>
                                    <div class="float-right">

                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div class="card w-100 border-0 mt-4">
                                <div class="card-title mb-0">
                                    <div class="float-left">
                                        <div class="media mt-2">
                                            <img src="@/assets/images/quera.jpg" class="place-logos" alt="quera">
                                            <div class="media-body ml-2">
                                                <span class="d-block"> {{
                                                        $t("codecup4_judge")
                                                    }} - <small> 50 {{ $t("participant") }} </small> </span>
                                                <span class="d-block"> Quera CodeCup 4 - PHP/Laravel </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="float-right mt-2">
                                        <small> {{ $t("codecup4_time") }} </small>
                                        <vs-button class="m-0 mt-1 ml-auto"
                                                   href="https://quera.ir/contest/assignments/5650/scoreboard"
                                                   size="mini"
                                                   transparent active blank color="dark">
                                            <i class='bx bx-chalkboard mr-1'></i> <span
                                                class="small-padding-top"> {{ $t("scoreboard") }} </span>
                                        </vs-button>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="w-100">
                                    <div class="float-left">

                                    </div>
                                    <div class="float-right">

                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div class="card w-100 border-0 mt-4">
                                <div class="card-body p-0">
                                    <div class="card-title mb-0">
                                        <div class="float-left">
                                            <div class="media mt-2">
                                                <img src="@/assets/images/quera.jpg" class="place-logos" alt="quera">
                                                <div class="media-body ml-2">
                                                    <span class="d-block"> {{
                                                            $t("connect6_rank")
                                                        }} - <small> 72 {{ $t("participant") }} </small> </span>
                                                    <span class="d-block"> Quera Connect 6 - PHP/Laravel </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="float-right mt-2">
                                            <small> {{ $t("connect6_time") }} </small>
                                            <vs-button class="m-0 mt-1 ml-auto"
                                                       href="https://quera.ir/course/assignments/4996/scoreboard"
                                                       size="mini" transparent active blank color="dark">
                                                <i class='bx bx-chalkboard mr-1'></i> <span class="small-padding-top"> {{
                                                $t("scoreboard")
                                                }} </span>
                                            </vs-button>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="w-100">
                                        <div class="float-left">

                                        </div>
                                        <div class="float-right">

                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="card w-100 border-0 mt-4">
                                <div class="card-title mb-0">
                                    <div class="float-left">
                                        <div class="media mt-2">
                                            <img src="@/assets/images/acm.png"
                                                 class="place-logos img-thumbnail border-0"
                                                 alt="acm">
                                            <div class="media-body ml-2">
                                                <span class="d-block"> {{
                                                        $t("acm_rank")
                                                    }} - <small> 60 {{ $t("participant") }} </small> </span>
                                                <span class="d-block"> ACM-ICPC Asia Region </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="float-right mt-2">
                                        <small> {{ $t("acm_time") }} </small>
                                        <vs-button class="m-0 mt-1 ml-auto" href="/images/sharif.png" size="mini"
                                                   transparent active blank color="dark">
                                            <i class='bx bx-certification mr-1'></i> {{ $t("certificate") }}
                                        </vs-button>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="w-100">
                                    <div class="float-left">

                                    </div>
                                    <div class="float-right">

                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div class="card w-100 border-0 mt-4">
                                <div class="card-title mb-0">
                                    <div class="float-left">
                                        <div class="media mt-2">
                                            <img src="@/assets/images/sharif.png" class="place-logos" alt="sharif">
                                            <div class="media-body ml-2">
                                                <span class="d-block"> {{ $t("fanavard_rank") }} </span>
                                                <span
                                                    class="d-block"> Fanavard - Sharif University Of Technology </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="float-right mt-2">
                                        <small> {{ $t("fanavard_time") }} </small>
                                        <vs-button class="m-0 mt-1 ml-auto" href="/images/ui.jpg" size="mini"
                                                   transparent
                                                   active blank color="dark">
                                            <i class='bx bx-certification mr-1'></i> {{ $t("certificate") }}
                                        </vs-button>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="w-100">
                                    <div class="float-left">

                                    </div>
                                    <div class="float-right">

                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-courses" role="tabpanel" aria-labelledby="pills-courses-tab">
                        <div class="card w-100 border-0">
                            <div class="media">
                                <figure class="figure m-0">
                                    <img src="@/assets/images/courses/db.png" class="courses-images rounded" alt="db">
                                    <figcaption class="figure-caption text-center courses-caption">
                                        <vs-avatar-group max="3">
                                            <vs-avatar>
                                                <img src="@/assets/images/courses/people/abolfazl.jpg" alt="abolfazl">
                                            </vs-avatar>
                                            <vs-avatar>
                                                <img src="@/assets/images/courses/people/nima.jpg" alt="nima">
                                            </vs-avatar>
                                            <vs-avatar>
                                                <i class='bx bx-user'></i>
                                            </vs-avatar>
                                            <vs-avatar>
                                                <i class='bx bx-user'></i>
                                            </vs-avatar>
                                            <vs-avatar>
                                                <i class='bx bx-user'></i>
                                            </vs-avatar>
                                        </vs-avatar-group>
                                    </figcaption>
                                </figure>
                                <div class="media-body mt-3 ml-2">
                                    <span class="d-block"> {{ $t("db_course") }} - {{ $t("quera") }} </span>
                                    <span class="d-block"> {{ $t("db_teachers") }} </span>
                                    <span class="d-block">
                                                <small>
                                                    <a href="https://quera.org/college/landpage/8939/database"
                                                       target="_blank"> {{ $t("register") }} </a>
                                                </small>
                                            </span>
                                </div>
                            </div>
                        </div>
                        <div class="card w-100 border-0">
                            <div class="media">
                                <figure class="figure m-0">
                                    <img src="@/assets/images/courses/linux.png" class="courses-images rounded"
                                         alt="linux">
                                    <figcaption class="figure-caption text-center courses-caption">
                                        <vs-avatar-group max="3">
                                            <vs-avatar>
                                                <img src="@/assets/images/courses/people/abolfazl.jpg" alt="abolfazl">
                                            </vs-avatar>
                                            <vs-avatar>
                                                <img src="@/assets/images/courses/people/salib.jpg" alt="salib">
                                            </vs-avatar>
                                            <vs-avatar>
                                                <i class='bx bx-user'></i>
                                            </vs-avatar>
                                            <vs-avatar>
                                                <i class='bx bx-user'></i>
                                            </vs-avatar>
                                            <vs-avatar>
                                                <i class='bx bx-user'></i>
                                            </vs-avatar>
                                            <vs-avatar>
                                                <i class='bx bx-user'></i>
                                            </vs-avatar>
                                        </vs-avatar-group>
                                    </figcaption>
                                </figure>
                                <div class="media-body mt-3 ml-2">
                                    <span class="d-block"> {{ $t("linux_course") }} - {{ $t("quera") }} </span>
                                    <span class="d-block"> {{ $t("linux_teachers") }} </span>
                                    <span class="d-block">
                                                <small>
                                                    <a href="https://quera.org/college/landpage/8903/linux"
                                                       target="_blank"> {{ $t("register") }} </a>
                                                </small>
                                            </span>
                                </div>
                            </div>
                        </div>
                        <div class="card w-100 border-0">
                            <div class="media">
                                <figure class="figure m-0">
                                    <img src="@/assets/images/courses/laravel.png" class="courses-images rounded"
                                         alt="laravel">
                                    <figcaption class="figure-caption text-center courses-caption">
                                        <vs-avatar-group max="3">
                                            <vs-avatar>
                                                <img src="@/assets/images/courses/people/abolfazl.jpg" alt="abolfazl">
                                            </vs-avatar>
                                            <vs-avatar>
                                                <img src="@/assets/images/courses/people/nima.jpg" alt="nima">
                                            </vs-avatar>
                                            <vs-avatar>
                                                <i class='bx bx-user'></i>
                                            </vs-avatar>
                                            <vs-avatar>
                                                <i class='bx bx-user'></i>
                                            </vs-avatar>
                                            <vs-avatar>
                                                <i class='bx bx-user'></i>
                                            </vs-avatar>
                                            <vs-avatar>
                                                <i class='bx bx-user'></i>
                                            </vs-avatar>
                                            <vs-avatar>
                                                <i class='bx bx-user'></i>
                                            </vs-avatar>
                                        </vs-avatar-group>
                                    </figcaption>
                                </figure>
                                <div class="media-body mt-3 ml-2">
                                    <span class="d-block"> {{ $t("laravel_course") }} - {{ $t("quera") }} </span>
                                    <span class="d-block"> {{ $t("laravel_teachers") }} </span>
                                    <span class="d-block">
                                                <small>
                                                    <a href="https://quera.org/college/landpage/7615/laravel"
                                                       target="_blank"> {{ $t("register") }} </a>
                                                </small>
                                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade posts-div pb-3" id="pills-blog" role="tabpanel"
                         aria-labelledby="pills-blog-tab" ref="blog">
                        <vs-card-group>
                            <vs-card v-for="article in articles" :key="article.slug" @click="handleClick(article.node)">
                                <template #title>
                                    <h3>{{ article.node.title }}</h3>
                                </template>
                                <template #img>
                                    <img :src="`${article.node.coverImage.url}`" :alt="`${article.node.slug}`">
                                </template>
                                <template #text>
                                    <p>
                                        {{ article.node.brief.substring(0, 60) }} ...
                                    </p>
                                </template>
                                <template #interactions>
                                    <vs-button danger icon>
                                        <i class='bx bx-heart'></i>
                                    </vs-button>
                                    <vs-button shadow icon>
                                        <i class='bx bx-calendar'></i>
                                        <span class="ml-1">{{
                                                moment(article.node.publishedAt).format('YYYY-MM-DD, HH:mm')
                                            }}</span>
                                    </vs-button>
                                </template>
                            </vs-card>
                        </vs-card-group>
                        <b> </b>
                        <div class="sections pt-0 pb-0 px-3 w-40 text-center">
                            <vs-button class="d-inline" href="https://blog.abolfazlmohajeri.ir" blank transparent active
                                       color="dark">
                                {{ $t("visit_blog") }}
                            </vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment/moment";

export default {
    name: "Achievement",
    data() {
        return {
            articles: null,
            dateOptions: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            }
        }
    },
    mounted() {
        const GET_USER_ARTICLES = `
            query GetUserArticles($first: Int!) {
                publication(host: "blog.abolfazlmohajeri.ir") {
                    title
                    posts(
                      first: $first
                    ) {
                        edges {
                            node {
                                slug
                                coverImage {
                                  url
                                }
                                title
                                brief
                                reactionCount
                                publishedAt
                            }
                        }
                    }
                }
            }
        `;
        this.gql(GET_USER_ARTICLES, {first: 5})
            .then(response => response.json())
            .then(response => {
                this.articles = response.data.publication.posts.edges;
            });
    },
    methods: {
        moment: function (date) {
            return moment(date);
        },

        gql(query, variables = {}) {
            let loading = this.$vs.loading({
                target: this.$refs.blog,
                type: 'corners',
                color: 'dark'
            });
            const data = fetch('https://gql.hashnode.com/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query,
                    variables
                })
            });
            loading.close();
            return data;
        },

        handleClick(article) {
            window.open(`https://blog.abolfazlmohajeri.ir/${article.slug}`);
        }
    }
}
</script>

<style>
.lined li {
    width: 33% !important;
}

.lined .nav-link {
    color: #787878;
    border: none;
    border-bottom: 3px solid transparent;
}

.lined .nav-link:hover {
    border: none;
    color: black;
    border-bottom: 3px solid transparent;
}

.lined .nav-link.active {
    background: none;
    color: black;
    border-color: black;
    padding-bottom: 12px !important;
}

.posts-div {
    font-family: MontserratMedium;
    direction: ltr;
    text-align: left;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.courses-caption {
    transform: translateY(-20px);
}

.extra-small {
    font-size: 0.7em;
    font-weight: 400;
}
</style>

<style scoped>

</style>
